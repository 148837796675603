import React from 'react';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import { Link } from 'react-router-dom';
import downloadAndroid from '../../assets/images/icon_google_play.png';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../styles/theme.js';
import backgroundImage from '../../assets/images/people_commerce.jpg';
import { Grid, Typography } from '@material-ui/core';
import PrimaryButton from '../../components/common/primaryButton';
import SecondaryButton from '../../components/common/secondaryButton';

const useStyles = makeStyles({
  background: {
    width: '100%',
    height: '100%',
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPositionY: 'bottom',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  whiteCircle: {
    width: '100%',
    backgroundColor: theme.palette.white.main,
    borderRadius: '50%',
    padding: 150,
    paddingBottom: 50,
    marginTop: -170,
    marginRight: -170,
    marginBottom: 50,
    width: 1100,
    height: 1100,
    [theme.breakpoints.up('lg')]: {
      ...theme.globals.centerVertical,
    },
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      width: '100%',
      backgroundColor: theme.palette.white.main,
      borderRadius: 0,
      padding: 0,
      margin: 0,
    },
  },
  headerContainer: {
    paddingTop: 40,
    paddingBottom: 40,
  },
  headerAndTextContainer: {
    padding: 50,
    [theme.breakpoints.down('xs')]: {
      padding: 20,
    },
  },
  textContainer: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleAndSubtitleContainer: {
    ...theme.globals.centerVertical,
    textAlign: 'center',
  },
  title: {
    ...theme.typography.title,
    fontSize: 48,
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: 40,
    },
  },
  subtitle: {
    ...theme.typography.subtitle,
    fontSize: 24,
    color: theme.palette.primary.main,
  },
  buttonContainer: {
    ...theme.globals.center,
    padding: 20,
    [theme.breakpoints.down('sm')]: {
      padding: 14,
    },
  },
  buttonText: {
    ...theme.typography.button,
    fontSize: 20,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  paragraph: {
    ...theme.typography.paragraph,
  },
  list: {
    ...theme.typography.paragraph,
    paddingTop: 20,
    paddingBottom: 20,
    width: '100%',
    paddingLeft: 20,
  },
  listItem: {
    ...theme.typography.paragraph,
    padding: 5,
  },
  link: {
    ...theme.typography.paragraph,
    color: theme.palette.primary.main,
    fontStyle: 'normal',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  textAndIconGroupContainer: {
    ...theme.globals.centerVertical,
    width: '100%',
    padding: 30,
  },
  iconGroupContainer: {
    ...theme.globals.center,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  iconContainer: {
    padding: 10,
    width: 200,
  },
  iconImage: {
    width: '100%',
  },
  linkButton: {
    width: '100%',
    textDecoration: 'none',
  },
});

export default function Comercios() {
  const classes = useStyles();
  return (
    <>
      <div className={classes.background}>
        <div container className={classes.container}>
          <div className={classes.whiteCircle}>
            <Grid container xs={12} className={classes.headerAndTextContainer}>
              <Grid container xs={12} className={classes.headerContainer}>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  md={8}
                  className={classes.titleAndSubtitleContainer}
                >
                  <Typography className={classes.title}>
                    Para Comercios
                  </Typography>
                  <Typography className={classes.subtitle}>
                    ¡Únete a nuestra red Nerito!
                  </Typography>
                </Grid>
                <Grid
                  container
                  xs={12}
                  sm={4}
                  md={4}
                  className={classes.buttonContainer}
                >
                  <Grid item xs={12}>
                    <Link to='/registro' target='_top'>
                      <PrimaryButton fullWidth>
                        <text className={classes.buttonText}>
                          ¡Unirme Ahora!
                        </text>
                      </PrimaryButton>
                    </Link>
                  </Grid>
                  <Grid item xs={12}>
                    <Link
                      className={classes.linkButton}
                      to={{
                        pathname: 'https://nerito.mx/login',
                      }}
                      target='_blank'
                    >
                      <SecondaryButton
                        fullWidth
                        marginVertical={10}
                        
                      >
                        <text className={classes.buttonText}>
                          Iniciar Sesión
                        </text>
                      </SecondaryButton>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.textContainer}>
                {/* <Typography className={classes.paragraph}>
                  Si te gustaría aceptar pagos con la red Nerito sin necesidad
                  de terminal de tarjetas ni ningún otro dispositivo que no sea
                  tu celular,{' '}
                  <Link to='/registro' target='_top' className={classes.link}>
                    regístrate aquí
                  </Link>
                  .
                </Typography> */}
                <Typography className={classes.paragraph}>
                  Al ser parte de nuestra red comercial, tendrás acceso a
                  nuestra aplicación de comercios, que te puede permitir:
                </Typography>
                <ul className={classes.list}>
                  <li className={classes.listItem}>
                    Controlar los stocks de tu negocio
                  </li>
                  <li className={classes.listItem}>
                    Aceptar pedidos desde el celular de tu cliente a distancia o
                    desde tu establecimiento
                  </li>
                  <li className={classes.listItem}>
                    Dar de alta a tus empleados para que tomen pedidos desde su
                    celular y controlen la cuenta
                  </li>
                  {/* <li className={classes.listItem}>
                    Manejar transferencias entre tu negocio y tus colaboradores
                  </li> */}
                  <li className={classes.listItem}>Y muchos servicios más</li>
                </ul>

                <Typography className={classes.paragraph}>
                  Si quieres formar parte de nuestra red y ayudarte en la
                  transformación digital de tu negocio, ponte en contacto con
                  nosotros en{' '}
                  <a
                    className={classes.link}
                    href={'mailto:atencion.empresas@nerito.com'}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    atencion.empresas@nerito.com
                  </a>
                </Typography>
                <div className={classes.textAndIconGroupContainer}>
                  <Typography className={classes.paragraph}>
                    Descarga la app para empresas:
                  </Typography>
                  <div className={classes.iconGroupContainer}>
                    {/* <div className={classes.iconContainer}>
                    <a
                      href='https://www.apple.com/app-store/'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <img
                        src={downloadIos}
                        alt=''
                        className={classes.iconImage}
                      />
                    </a>
                  </div> */}
                    <div className={classes.iconContainer}>
                      <a
                        href='https://play.google.com/store/apps/details?id=com.neritoempresas'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <img
                          src={downloadAndroid}
                          alt=''
                          className={classes.iconImage}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
}
