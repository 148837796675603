import React from 'react';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import neritoLogoWhiteLetters from '../../../assets/images/nerito_logo.svg';
import neritoLogoGreenLetters from '../../../assets/images/neritoLogoGreen.svg';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    backgroundColor: props=> (props.whiteLetters ? '#3B9A83' : 'transparent'),
    width: '100%',
    marginBottom: '3%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    width: 160,
  },
});

export default function LegalHeader(props) {
  useEffect(() => {}, []);

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img
        className={classes.logo}
        src={
          props.greenLetters ? neritoLogoGreenLetters :neritoLogoWhiteLetters 
        }
        alt='nerito_logosvg'
      />
    </div>
  );
}
