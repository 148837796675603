import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withSnackbar } from 'notistack';
import '../../assets/css/main.css';
import LegalHeader from '../headers/legalHeader';
import CloseButton from '../common/closeButton';
import theme from '../../styles/theme.js'

const rem = 16;

const useStyles = makeStyles({
  container: {
    width: '50vw',
    maxHeight: '60vh',
    overflow: 'scroll',
  },
  closeHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  defaultText: {
    textAlign: 'left',
    fontFamily: 'Nunito',
    fontWeight: 500,
  },
  title: {
    fontSize: 2.25 * rem,
    fontWeight: 700,
  },
  subTitle: {
    fontSize: 1.25 * rem,
    fontWeight: 700,
  },
  link: {
    color: theme.palette.primary.main,
  },
});

const ModalCookies = (props) => {
  const classes = useStyles();

  const [isLoading] = useState(false);
  
  return (
    <div className={`${classes.container} scrollBarHidden`}>
      <div className={classes.closeHeader}>
        <CloseButton type='button' onClick={props.handleClose} />
      </div>
      <LegalHeader greenLetters></LegalHeader>
      {isLoading ? (
        <div className='styleCircularProgress'>
          <CircularProgress color='secondary' />
        </div>
      ) : (
          <div>
            <div>
              <text
                className={`${classes.title} ${classes.defaultText}`}
              >
                Cookies
              </text>
              <br />
              <br />
            </div>
            <div>
              <text className={`${classes.defaultText}`}>
                El Usuario que utilice la Plataforma, conviene en recibir los archivos denominados “Cookies”, que les transmitan los servidores de “La Empresa”, los cuales se almacenan en el disco duro de la computadora del Usuario cuando éste tiene acceso a las páginas web de la Plataforma. Dichos archivos pueden contener información tal como la identificación proporcionada por el Usuario o información para rastrear las páginas que el Usuario ha visitado. Una Cookie no puede leer los datos o información del disco duro del Usuario ni leer las Cookies creadas por otros sitios o páginas.
                      </text>
            </div>
          </div>
        )}
    </div >
  );
}

export default withSnackbar(ModalCookies);
