import React from 'react';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../styles/theme.js';
import backgroundImage from '../../assets/images/people_security.jpg';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  background: {
    width: '100%',
    height: '100%',
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPositionY: 'bottom',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  whiteCircle: {
    width: '100%',
    backgroundColor: theme.palette.white.main,
    borderRadius: '50%',
    padding: 150,
    paddingBottom: 50,
    marginTop: -170,
    marginRight: -170,
    marginBottom: 50,
    width: 1100,
    height: 1100,
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      width: '100%',
      backgroundColor: theme.palette.white.main,
      borderRadius: 0,
      padding: 0,
      margin: 0,
    },
  },
  brightGreenText: {
    color: '#44a38b',
    fontStyle: 'normal',
    '&:hover &:focus': {
      color: 'red',
    },
  },
  headerContainer: {},
  headerAndTextContainer: {
    padding: 100,
    ...theme.globals.centerVertical,
    [theme.breakpoints.down('xs')]: {
      padding: 20,
    },
  },
  textContainer: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: 600,
  },
  titleAndSubtitleContainer: {
    ...theme.globals.centerVertical,
  },
  title: {
    ...theme.typography.title,
    fontSize: 48,
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: 40,
    },
  },
  subtitle: {
    ...theme.typography.subtitle,
    fontSize: 24,
    color: theme.palette.primary.main,
  },
  buttonContainer: {
    ...theme.globals.center,
    padding: 20,
  },
  button: {
    ...theme.typography.button,
    fontSize: 20,
  },
  paragraph: {
    ...theme.typography.paragraph,
    fontSize: 20,
    padding: 20,
  },
  list: {
    ...theme.globals.centerVertical,
    height: '100%',
    width: '100%',
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
  },
  listItem: {
    ...theme.typography.paragraph,
      fontSize: 20,
    padding: 15,
  },
  link: {
    ...theme.typography.paragraph,
    color: theme.palette.primary.main,
    fontStyle: 'normal',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  textAndIconGroupContainer: {
    ...theme.globals.centerVertical,
    width: '100%',
    padding: 30,
  },
  iconGroupContainer: {
    ...theme.globals.center,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  iconContainer: {
    padding: 10,
    width: 200,
  },
  iconImage: {
    width: '100%',
  },
});

export default function Servicios() {
  const classes = useStyles();
  return (
    <>
      <div className={classes.background}>
        <div container className={classes.container}>
          <div className={classes.whiteCircle}>
            <Grid container xs={12} className={classes.headerAndTextContainer}>
              <Grid container xs={12} className={classes.headerContainer}>
                <Grid
                  item
                  xs={12}
                  className={classes.titleAndSubtitleContainer}
                >
                  <Typography className={classes.title}>
                  Uso seguro
                  </Typography>
                  <Typography className={classes.subtitle}>
                    ¡Únete a Nerito!
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.textContainer}>
                <ul className={classes.list}>
                  <li className={classes.listItem}>
                  Conectaremos a nuestros usuarios de forma segura por lo que tus datos confidenciales estarán protegidos con la encriptación más avanzada.
                  </li>
                  {/* <li className={classes.listItem}>
                  Estamos en proceso de regularnos por la Comisión Nacional
                    Bancaria y de Valores y las autoridades financieras como
                    Institución de Fondos de Pago Electrónico (IFPE) en México.
                  </li> */}
                  <li className={classes.listItem}>
                  Tus datos personales, de tu negocio y de tus operaciones están protegidos de acuerdo a lo establecido por el INAI (Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales).
                  </li>
                </ul>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
}
