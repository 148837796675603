import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownLong } from '@fortawesome/free-solid-svg-icons';
import { faGooglePlay, faAppStore } from '@fortawesome/free-brands-svg-icons';

import '../../App.css';
import theme from '../../styles/theme.js';
import AppImage1 from '../../assets/images/appImage1.svg';
import AppImage2 from '../../assets/images/appImage2.svg';
import NeritoOurApp from '../../assets/images/NeritoOurApp.svg';
import NeritoLogoBig from '../../assets/images/logos/newNeritoLogoBig.svg';

const OurApp = (props) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.topContainer}>
        <div className={classes.leftContainer}>
          <img src={NeritoOurApp} className={classes.imgContainer} />
        </div>
        <div className={classes.rightContainer}>
          <img src={NeritoLogoBig}  className={classes.imgContainer}/>
          <Typography className={classes.blackTitle}>La app perfecta para tus</Typography>
          <Typography className={classes.whiteTitle}>finanzas y compras</Typography>
          <div className={classes.downloadContainer}>
            <Typography className={classes.thinTitle}>DESCARGA LA APP AHORA EN: </Typography>
            <div className={classes.iconsContainer}>
              <a
                href='https://play.google.com/store/apps/details?id=com.flexwallet'
                target='_blank'
                rel='noopener noreferrer'
              >
                <FontAwesomeIcon icon={faGooglePlay} size='4x' color={theme.palette.secondary.main} />
              </a>
              <a
                href='https://play.google.com/store/apps/details?id=com.flexwallet'
                target='_blank'
                rel='noopener noreferrer'
              >
                <FontAwesomeIcon icon={faAppStore} size='4x' color={theme.palette.secondary.main} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.showMoreContainer}>
        <div className={classes.showMoreTextContainer}>
          <Typography className={classes.showMoreText}>CONOCE MÁS</Typography>
          <FontAwesomeIcon icon={faArrowDownLong} size='2x' color={theme.palette.secondary.main} />
        </div>
      </div>
      <div className={classes.infoContainer}>
        <div className={classes.infoAndImageContainer}>
          <div className={classes.titleContainer}>
            <Typography className={classes.blackTitle2}>Además lo que </Typography>
            <Typography className={classes.greenTitle}>ya es estándar </Typography>
            <Typography className={classes.blackTitle2}>a tu disposición</Typography>
          </div>
          <img src={AppImage1} className={classes.imgContainer}/>
        </div>

        <div className={classes.infoAndImageContainer}>
          <img src={AppImage2} className={classes.imgContainer}/>
          <div className={classes.listContainer}>
            <div className={classes.titleContainer}>
              <Typography className = {classes.infoBold}>
                Con una cuenta en nerito, tu podras:
              </Typography>
              
              <Typography className = {classes.info}>
                Ahorrar en comisiones del banco​
              </Typography>
            </div>
            <ul>
              <li className = {classes.list}>
                Acceder a pagos de servicios como CFE, Telmex, SACMEX, entre otros, 
                directamente desde tu cuenta Nerito
              ​</li>
              <li className = {classes.list}>
                Cargar tiempo aire para tu celular 
                para que nunca estes desconectado​
              ​</li>
              <li className = {classes.list}>
                Adquirir tarjetas de regalos 
                y otros monederos como Google Play, Spotify​, Amazon, entre otros.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default OurApp;

const useStyles = makeStyles({
  topContainer: {
    width: '100%',
    backgroundColor: theme.palette.primary.secondary,
    paddingTop: '100px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse'
    },
  },
  imgContainer: {
    // width: '80%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  listContainer: {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '70%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  leftContainer: {
    width: '40%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    textAlign: 'center'
  },
  rightContainer: {
    width: '60%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
  },
  infoBold: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: '20px',
  },
  blackTitle: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: 50,
    color: theme.palette.black.main,
    lineHeight: 1,
    // textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      fontSize: 50,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 35
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 35,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  whiteTitle: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: 50,
    color: theme.palette.white.main,
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      fontSize: 50,
    },
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      fontSize: 35
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 35,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  downloadContainer: {
    marginTop: 50,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      marginTop: 20
    },
  },
  thinTitle: {
    fontFamily: 'Lato',
    fontWeight: '300',
    fontSize: 40,
    textTransform: 'uppercase',
    [theme.breakpoints.down('lg')]: {
      fontSize: 40,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 35
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 35,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  iconsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '70%',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginTop: '20px'
  },
  showMoreContainer: {
    height: '150px',
    backgroundColor: theme.palette.primary.main,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  showMoreTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '20%',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('lg')]: {
      width: '20%',
    },
    [theme.breakpoints.down('md')]: {
      width: '30%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '50%',
    },
  },
  showMoreText: {
    fontFamily: 'Lato',
    fontWeight: '200',
    color: theme.palette.white.main,
    fontSize: 32
  },
  infoContainer: {
    backgroundColor: theme.palette.white.main,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  infoAndImageContainer: {
    width: '80%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    gap: 50,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      gap: 30,
    },
  },
  greenTitle: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: 70,
    color: theme.palette.primary.main,
    [theme.breakpoints.down('lg')]: {
      fontSize: 55,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 30
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 25,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  thinTitle2: {
    fontFamily: 'Lato',
    fontWeight: '300',
    fontSize: 70,
    textTransform: 'uppercase',
    [theme.breakpoints.down('lg')]: {
      fontSize: 55,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 30
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 25,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  blackTitle2: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: 70,
    color: theme.palette.black.main,
    lineHeight: 1,
    // textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      fontSize: 55,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 30
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 25,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  info: {
    fontFamily: 'Lato',
    fontSize: 20,
    color: theme.palette.black.main,
    [theme.breakpoints.down('lg')]: {
      fontSize: 20,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 18
    },
  },
  list: {
    lineHeight: 2,
    fontSize: 20,
    '&::marker': {
      color: theme.palette.yellow.main
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 18
    },
  },
  titleContainer: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 30 
    },
  }
});
