import React from 'react';
import '../../App.css';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../styles/theme.js';
import { Typography } from '@material-ui/core';
import VerticalLineDecoration from '../common/verticalLineDecoration';

const MoreOurApp = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.rowContainer}>
      
      <div className={classes.halfContainer}>
        <Typography className = {classes.blackTitle}>
            Adémas te <br/> simplifica la vida
        </Typography>
        <div className={classes.textContainer}>
          <Typography className = {[classes.info,classes.textJustify]}>
            En menos de 10 minutos tienes tu cuenta y no necesitas tener una cuenta bancaria para abrir una en nerito
          </Typography>
          <VerticalLineDecoration/>

          <Typography className = {[classes.info,classes.textJustify]}>
            Divide la cuenta con tus amigos, armen tandas y lleva control de ellas.
          </Typography>
          <VerticalLineDecoration/>

          <Typography className = {[classes.info,classes.textJustify]}>
            Un mapa te indicará donde puedes usar Nerito para consumir, depositar o retirar.
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default MoreOurApp;

const useStyles = makeStyles({
  textJustify: {
    textAlign: 'justify',
    width: '100%',
  },
  blackTitle: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: 60,
    width: '100%',
    color: theme.palette.black.main,
    padding: 0,
    paddingRight: 50,
    textAlign: 'right',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      fontSize: 40
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 35,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  textContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 30,
    width: '100%',
    height: '100%',
    padding: 50,
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      gap: 20,
      padding: 30,
      flexDirection: 'column',
    },
  },
  info: {
    fontFamily: 'Montserrat',
    fontWeight: '500',
    fontSize: 20
  },
  halfContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingHorizontal: '30px',
    marginTop: '3%'
  },
});