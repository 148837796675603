import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../../styles/theme.js';
import MenuIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
  container: {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    border: 'none',
    paddingLeft: (props) => (props.paddingHorizontal || 10),
    paddingRight: (props) => (props.paddingHorizontal || 10),
    paddingTop: 5,
    paddingBottom: 5,
    marginLeft: 5,
    marginRight: 5,
    marginTop: (props) => (props.marginVertical || 5),
    marginBottom: (props) => (props.marginVertical || 5),
    width: (props) => (props.fullWidth ? '100%' : 'auto'),
    alignItems: (props) => props.alignItems || 'center', // align vertically
    ...theme.typography.button,
  },
});

const CloseButton = (props) => {
  const classes = useStyles(props);

  return (
    <>
      <button
        type={props.type || 'button'}
        onClick={props.onClick}
        className={classes.container}
      >
        <MenuIcon />
      </button>
    </>
  );
};

export default CloseButton;
