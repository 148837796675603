import React from 'react';
import '../../App.css';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../styles/theme.js';
import { Typography } from '@material-ui/core';
import VerticalLineDecoration from '../common/verticalLineDecoration';

const MoreBusiness = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.rowContainer}>
      
      <div className={classes.halfContainer}>
        <Typography className = {classes.blackTitle}>
            Adémas te permite <br/> alcanzar mas mercados
        </Typography>
        <div className={classes.textContainer}>
            <Typography className = {[classes.info,classes.textCenter]}>
            Al abrir tu cuenta, puedes adicionar tu tienda en linea, y que crees? No te quitamos grandes % de tus ingresos como lo hacen otras plataformas
            </Typography>
            <VerticalLineDecoration/>

            <Typography className = {[classes.info,classes.textCenter]}>
                Puedes tambien remunerar al participar como punto de acceso de depositos y retiros de efectivo para otros usuarios Nerito​
            </Typography>
            <VerticalLineDecoration/>

            <Typography className = {[classes.info,classes.textCenter]}>
              Automaticamente, tu tienda en linea es un menu para tu establecimiento en fisico
            </Typography>
            <VerticalLineDecoration/>

            <Typography className = {[classes.info,classes.textCenter]}>
              Puedes incluir promociones, ofertas o crear tus monederos de premios para tus clientes todo en uno​
            </Typography>
        </div>
      </div>
    </div>
  )
}

export default MoreBusiness;

const useStyles = makeStyles({
  textCenter: {
    textAlign: 'center',
    width: '100%',
  },
  blackTitle: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: 60,
    // textAlign: 'center',
    width: '100%',
    color: theme.palette.black.main,
    padding: 0,
    paddingLeft: '50px',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      fontSize: 40
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 35,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  textContainer: {
    display: 'flex',
    gap: 30,
    width: '100%',
    height: '100%',
    padding: 50,
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      gap: 20,
      padding: 30,
      flexDirection: 'column',
    },
  },
  info: {
    fontFamily: 'Montserrat',
    fontWeight: '500',
    fontSize: 20,
    alignSelf: 'center'
  },
  halfContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    // paddingHorizontal: '30px',
    marginTop: '3%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
});