import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../../styles/theme.js'

const VerticalLineDecoration = (props) => {
    const classes = useStyles(props);

  return (
    <div className={classes.container}></div>
  )
}

export default VerticalLineDecoration


const useStyles = makeStyles({
  container: {
    height: '100%',
    borderRight: `5px solid ${theme.palette.yellow.main}`,
    [theme.breakpoints.down('sm')]: {
      borderBottom: `5px solid ${theme.palette.yellow.main}`,
      width: '100%'
    },
  },
});