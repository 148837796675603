import React from 'react';
import '../../App.css';
import './Home.css';
import { makeStyles } from '@material-ui/core/styles';
import backgroundImage from '../../assets/images/homeBackground.png';
import theme from '../../styles/theme.js';
import { Grid, Typography } from '@material-ui/core';
import NeritoLogoBig from '../../assets/images/logos/newNeritoLogoBig.svg';
import OurApp from '../../components/HomeComponents/OurApp';
import Business from '../../components/HomeComponents/Business';
import OurGroup from '../../components/HomeComponents/OurGroup';
import Contact from '../../components/HomeComponents/Contact';
import MoreBusiness from '../../components/HomeComponents/moreBusiness';
import MoreOurApp from '../../components/HomeComponents/moreOurApp';
import Carousel from '../../components/HomeComponents/Carousel';

const Home = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Grid container id='top'>
        <Grid item xs={12} lg={12}>
          <div className={classes.imageAndTextContainer}>
            <img src={NeritoLogoBig} alt='' className={classes.neritoLogo} />
            <Typography className={classes.title}>
              Servicios financieros a tu medida <br />
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} lg={12}>
          <div className={classes.textContainer}>
            <Typography className={classes.blackTitle}>
              Sin importar si es para
            </Typography>
            <Typography className={classes.greenTitle}>
              tu negocio <span className={classes.blackTitle}>y para </span>
              tu uso personal<span className={classes.blackTitle}>,</span>
            </Typography>
            <Typography className={classes.thinTitle}>
              desencadenate de los bancos, gana libertad y potencializa tus ganacias
            </Typography>
          </div>
        </Grid>
        <Grid container >
          {/* <Grid item xs={12} lg={12}> */}
          <Grid item style={{width: '100%'}}>
            <Carousel/>
          </Grid>
        </Grid>
      </Grid>
      <Grid container id="OurApp">
        <Grid item xs={12} lg={12}>
          <OurApp />
        </Grid>
      </Grid>
      <Grid container >
        <Grid item xs={12} lg={12}>
          <MoreOurApp/>
        </Grid>
      </Grid>
      <Grid container id="NeritoBusiness">
        <Grid item xs={12} lg={12}>
          <Business />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} lg={12}>
          <MoreBusiness/>
        </Grid>
      </Grid>
      <Grid container id="OurGroup">
        <Grid item xs={12} lg={12}>
          <OurGroup />
        </Grid>
      </Grid>
    </div>
  );
}

export default Home;

const useStyles = makeStyles({
  container: {
    ...theme.globals.centerVertical,
    width: '100%',
    backgroundColor: theme.palette.white.main,
  },
  imageAndTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    padding: '150px 0px',
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPositionY: 'top',
    backgroundPositionX: 'center',
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
      padding: '20%',
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      padding: '30%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  neritoLogo: {
    width: '40%',
    ...theme.globals.center,
    [theme.breakpoints.down('md')]: {
      width: '300px',
    },
  },
  title: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: 32,
    color: theme.palette.white.main,
    textAlign: 'left',
    [theme.breakpoints.down('lg')]: {
      fontSize: 32,
    },
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      fontSize: 32
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 32,
      textAlign: 'center',
    },
  },
  greenTitle: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: 60,
    textAlign: 'center',
    color: theme.palette.primary.main,
    padding: 0,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      fontSize: 40
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 35,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 28,
    },
  },
  blackTitle: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: 60,
    textAlign: 'center',
    color: theme.palette.black.main,
    padding: 0,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      fontSize: 40
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 35,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 28,
    },
  },
  thinTitle: {
    fontFamily: 'Lato',
    fontWeight: '300',
    fontSize: 30,
    [theme.breakpoints.down('md')]: {
      fontSize: 25
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
      textAlign: 'center',
    },
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    padding: 50,
    marginTop: '20px',
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
      padding: 30,
    },
    [theme.breakpoints.down('xs')]: {
      padding: 10,
    },
  },
});
