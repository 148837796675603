import React from "react";
import "./Ayuda.css";
import {QuestionItems} from "./QuestionItems";
import {Link} from "react-router-dom";
import Accordion from "../../components/Accordion";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import backGroundImage from '../../assets/images/web_help_5.jpg';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../styles/theme.js'

const useStyles = makeStyles({

  faqContainer:{
    backgroundImage: `url(${backGroundImage})`,
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: '3% 15%',
    color: '#fff',
    [theme.breakpoints.down('sm')]: {
      padding: '3% 5%'
    },
  },
  titleArea: {
    paddingBottom: '2%'
  },
  bottomArea: {
    paddingTop: '5%'
  },
  title: {
    fontSize: '2rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem'
    },
  },
  faqMessage: {
    fontSize: '1.6rem',
    paddingTop: '1vh',
    maxWidth: '600px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.3rem',
      paddingTop: '1vh'
    },
  },
  brightYellowText: {
    color: '#eddd64',
    fontStyle: 'normal',
    '&:hover': {
      color: '#fff',
    },
  },

})


const Ayuda = () => {

  const classes = useStyles();

  return (
    <>
      <div>
        <div className={classes.faqContainer}>
          <div className="NavBar-area"></div>
          <div className={classes.titleArea}>
            <h1 className={classes.title} >Preguntas frecuentes</h1>
          </div>
          <div className={classes.faqBoard}>
            {QuestionItems.map((item, index) => {
              return (
                <Accordion
                  title={item.question}
                  content={item.answer1}
                ></Accordion>
              );
            })}
          </div>
          <div className={classes.bottomArea}>
            <h1 className={classes.title}>¿En qué te podemos ayudar?</h1>
            <p className={classes.faqMessage}>
              Si tienes alguna duda sobre la app, nosotros de podemos ayudar{" "}
              <Link className={classes.brightYellowText} to="/contacto" target="_top">
                ¡Mándanos un mensaje!
              </Link>{" "}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Ayuda;

