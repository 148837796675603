import React from 'react';
import '../../App.css';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../styles/theme.js';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import SecondaryButton from '../../components/common/secondaryButton';

import Carousel01 from '../../assets/images/carousel/carousel-01.png';
import Carousel02 from '../../assets/images/carousel/carousel-02.png';
import Carousel03 from '../../assets/images/carousel/carousel-03.png';
import PrimaryCarousel from '../common/PrimaryCarousel';

const OurCarousel = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.rowContainer}>
      
      <div className={classes.halfContainer}>
        <div className={classes.textContainer}>
            <Typography className={classes.greenTitle}> Todas tu finanzas</Typography>
            <Typography className={classes.blackTitle}>en una sola app</Typography>
            <Typography className={classes.thinTitle}>DESCARGA, INSTALA y  FACILITA TUS PAGOS</Typography>
            <Link to='/nuestraApp' target='_top' >
              <SecondaryButton 
                marginVertical={'20px'} 
                buttonText={classes.buttonText} 
                minWidth={'300px'}
              >
                Descargar Nerito
              </SecondaryButton>
            </Link>
        </div>
      </div>

      <div className={classes.halfContainer}>
        <PrimaryCarousel
          data = {[Carousel01,Carousel02,Carousel03]}
        />
      </div>

    </div>
  )
}

export default OurCarousel;

const useStyles = makeStyles({
  buttonText: {
    fontFamily: 'Montserrat',
    fontWeight: '600',
    fontSize: '24px',
  },
  greenTitle: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: 60,
    textAlign: 'center',
    color: theme.palette.primary.main,
    padding: 0,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      fontSize: 40
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 35,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  blackTitle: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: 60,
    textAlign: 'center',
    color: theme.palette.black.main,
    padding: 0,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      fontSize: 40
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 35,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  thinTitle: {
    fontFamily: 'Lato',
    fontWeight: '300',
    fontSize: 24,
    [theme.breakpoints.down('md')]: {
      fontSize: 20
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // justifyContent: 'center',
    width: '80%',
    height: '100%',
    padding: 50,
    textAlign: 'justify',
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
      padding: 30,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: 10,
    },
  },
  halfContainer: {
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingHorizontal: '30px',
    marginTop: '100px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  buttonText: {
    fontFamily: 'Montserrat',
    fontWeight: '600',
    fontSize: '24px',
  },
  carousel: {
    display: 'flex',
    justifyContent: 'center'
  }
});