import React from 'react';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import neritoLogoGreen from '../../../assets/images/neritoLogoGreen.svg';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    backgroundColor: 'white',
    width: '100%',
    marginTop: 10,
    marginBottom: 10,
    alignItems: 'center',
    justifyContent: 'center'
  },
  logo: {
    width: 160
  }
});

export default function FirstHeader(props) {
  useEffect(()=>{},[])

  const classes = useStyles();
  return (
    <div className={classes.root}>
        <img className={classes.logo} src={neritoLogoGreen} alt="nerito_logosvg"/>
    </div>
  );
}