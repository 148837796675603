import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Amplify from 'aws-amplify';
import awsmobile from './aws-exports';

Amplify.configure(awsmobile);

ReactDOM.render(<App />, document.getElementById("root"));

const script_google_analytics =  document.createElement("script");
script_google_analytics.async = true;
script_google_analytics.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_ANALYTICS}`
document.head.appendChild(script_google_analytics);

const script_google_analytics_function = document.createElement('script');
const gtagFunction = document.createTextNode(`window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments)};gtag('js', new Date());gtag('config', '${process.env.REACT_APP_GOOGLE_ANALYTICS}');`);
script_google_analytics_function.appendChild(gtagFunction);
document.head.appendChild(script_google_analytics_function);