import React, { Component } from 'react';
import './Contacto.css';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import TextareaAutosize from 'react-textarea-autosize';
import emailjs from 'emailjs-com';
import ReCAPTCHA from 'react-google-recaptcha';
import CheckValue from '../../lib/formatValidations';

Modal.setAppElement('#root');

export default class Contacto extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categoryValue: '',
      // form fields
      businessName: '',
      contactName: '',
      businessPhoneNumber: '',
      contactEmail: '',
      businessState: '',
      businessCity: '',
      message: '',
      // changes from BUSINESS mode to CONTACT mode
      businessMode: false,
      privacyCheckbox: false,
      // reCAPTCHA
      successRecaptcha: false,
      successModalVisible: false,
      formIsComplete: false,
      // validation alerts
      categoryValueAlert: '',
      businessNameAlert: '',
      contactNameAlert: '',
      businessPhoneNumberAlert: '',
      contactEmailAlert: '',
      businessStateAlert: '',
      businessCityAlert: '',
      messageAlert: '',
      privacyCheckboxAlert: '',
      successRecaptchaAlert: '',
    };
  }

  render() {
    const {
      businessMode,
      formIsComplete,
      successModalVisible,
      categoryValueAlert,
      businessNameAlert,
      contactNameAlert,
      businessPhoneNumberAlert,
      contactEmailAlert,
      businessStateAlert,
      businessCityAlert,
      messageAlert,
      privacyCheckboxAlert,
      successRecaptchaAlert,
    } = this.state;
    return (
      <>
        {/* <div className='NavBar-area'></div> */}
        <div className='contact-container'>
            <div className='single-row'>
              <label className='contact-title'>¡Contáctanos!</label>
            </div>
          <form onSubmit={this.sendEmail} className='form-container'>
            <div className='single-row '>
              <select
                value={this.state.categoryValue}
                className={
                  categoryValueAlert ? 'select-box red-border' : 'select-box'
                }
                name='categoryValue'
                onChange={this.handleSelectChange}
              >
                <option value=''>Selecciona una Categoría</option>
                <option value='business-registration'>
                  Atención a Empresas
                </option>
                <option value='tech-support'>Soporte Técnico</option>
                <option value='work-with-us'>Trabaja con Nosotros</option>
                <option value='data-privacy'>Privacidad de Datos</option>
                <option value='other'>Otro</option>
              </select>
            </div>
            <div className='single-row blank-div'>
              <span className='alertSpan'>
                {categoryValueAlert ? categoryValueAlert : null}
              </span>
            </div>
            <div className='single-row'>
              <input
                type='text'
                placeholder='Nombre de Negocio'
                className={
                  businessNameAlert ? 'text-box red-border' : 'text-box'
                }
                name='businessName'
                value={this.state.businessName}
                onChange={this.handleInputChange}
                style={{ display: this.state.businessMode ? 'block' : 'none' }}
              />
            </div>
            {businessMode ? (
              <div className='single-row blank-div'>
                <span className='alertSpan'>
                  {businessNameAlert ? businessNameAlert : null}
                </span>
              </div>
            ) : null}

            <div className='single-row'>
              <input
                type='text'
                placeholder='Nombre de Contacto'
                className={
                  contactNameAlert ? 'text-box red-border' : 'text-box'
                }
                name='contactName'
                value={this.state.contactName}
                onChange={this.handleInputChange}
              />
            </div>
            <div className='single-row blank-div'>
              <span className='alertSpan'>
                {contactNameAlert ? contactNameAlert : null}
              </span>
            </div>

            <div className='single-row'>
              <input
                type='number'
                placeholder='Escribe tu número de teléfono'
                className={
                  businessPhoneNumberAlert ? 'text-box red-border' : 'text-box'
                }
                name='businessPhoneNumber'
                value={this.state.businessPhoneNumber}
                onChange={this.handleInputChange}
                style={{ display: this.state.businessMode ? 'block' : 'none' }}
              />
            </div>
            {businessMode ? (
              <div className='single-row blank-div'>
                <span className='alertSpan'>
                  {businessPhoneNumberAlert ? businessPhoneNumberAlert : null}
                </span>
              </div>
            ) : null}

            <div className='single-row'>
              <input
                type='email'
                placeholder='Email'
                className={
                  contactEmailAlert ? 'text-box red-border' : 'text-box'
                }
                name='contactEmail'
                value={this.state.contactEmail}
                onChange={this.handleInputChange}
              />
            </div>
            <div className='single-row blank-div'>
              <span className='alertSpan'>
                {contactEmailAlert ? contactEmailAlert : null}
              </span>
            </div>

            <div className='single-row'>
              <div className='left-mail'>
                <input
                  type='text'
                  placeholder='Estado'
                  className={
                    businessStateAlert ? 'text-box red-border' : 'text-box'
                  }
                  name='businessState'
                  value={this.state.businessState}
                  onChange={this.handleInputChange}
                  style={{
                    display: this.state.businessMode ? 'block' : 'none',
                  }}
                />
                {businessMode ? (
                  <div className='single-row blank-div'>
                    <span className='alertSpan'>
                      {businessStateAlert ? businessStateAlert : null}
                    </span>
                  </div>
                ) : null}
              </div>
              <div className='right-mail'>
                <input
                  type='text'
                  placeholder='Ciudad'
                  className={
                    businessCityAlert ? 'text-box red-border' : 'text-box'
                  }
                  name='businessCity'
                  value={this.state.businessCity}
                  onChange={this.handleInputChange}
                  style={{
                    display: this.state.businessMode ? 'block' : 'none',
                  }}
                />
                {businessMode ? (
                  <div className='single-row blank-div'>
                    <span className='alertSpan'>
                      {businessCityAlert ? businessCityAlert : null}
                    </span>
                  </div>
                ) : null}
              </div>
            </div>

            <div className='single-row'>
              <TextareaAutosize
                placeholder='Escribe un Mensaje'
                className={
                  messageAlert
                    ? 'text-box textarea-autosize red-border'
                    : 'text-box textarea-autosize'
                }
                minRows='5'
                name='message'
                value={this.state.message}
                onChange={this.handleInputChange}
              ></TextareaAutosize>
            </div>
            <div className='single-row blank-div'>
              <span className='alertSpan'>
                {messageAlert ? messageAlert : null}
              </span>
            </div>

            <div className='single-row'>
              <input
                type='checkbox'
                checked={this.state.privacyCheckbox}
                name='privacyCheckbox'
                onChange={this.handleCheckboxChange}
              />
              <span className='text-after-checkbox'>
                He leído y aceptado los{' '}
                <Link className='privacy-link' to='/terminos' target='_top'>
                  Términos y Condiciones
                </Link>
                .
              </span>
            </div>
            <div className='single-row blank-div'>
              <span className='alertSpan'>
                {privacyCheckboxAlert ? privacyCheckboxAlert : null}
              </span>
            </div>

            <ReCAPTCHA
              sitekey='6Lfh8eQZAAAAAKQE_NPOpquFD5ecoS2xe_Bv9rFx'
              onChange={this.handleRecaptcha}
            ></ReCAPTCHA>
            <div className='single-row blank-div'>
              <span className='alertSpan'>
                {successRecaptchaAlert ? successRecaptchaAlert : null}
              </span>
            </div>
            <div className='single-row'>
              <input
                type='submit'
                value='Enviar'
                className={
                  formIsComplete
                    ? 'send-button green-submit-button'
                    : 'send-button'
                }
                disabled={!formIsComplete}
              />
            </div>
            <Modal
              isOpen={successModalVisible}
              // onRequestClose={() => setModalIsOpen(false)}
              style={{
                overlay: {
                  backgroundColor: 'transparent',
                },
                content: {
                  top: '50%',
                  left: '50%',
                  right: 'auto',
                  bottom: 'auto',
                  marginRight: '-50%',
                  transform: 'translate(-50%, -50%)',
                },
              }}
            >
              <div className='div-modal'>
                <h2>Mensaje enviado</h2>
                <br />
                <p>¡Gracias!</p>
                <p>Recibirás respuesta en tu buzón de e-mail.</p>
                <div className='div-modal-button'>
                  <button
                    className='button-modal'
                    onClick={() =>
                      this.setState({ successModalVisible: false })
                    }
                  >
                    Cerrar
                  </button>
                </div>
              </div>
            </Modal>
          </form>
        </div>
      </>
    );
  }

  sendLead = (e) => {
    e.preventDefault();
    const {
      businessName,
      contactName,
      businessPhoneNumber,
      contactEmail,
      businessState,
      businessCity,
    } = this.state;

    console.log(this.state);
    console.log(process.env);

    fetch(process.env.REACT_APP_ZAPIER_LEADS_ENDPOINT, {
      method: 'POST',
      body: JSON.stringify({
        businessName,
        contactName,
        businessPhoneNumber,
        contactEmail,
        businessState,
        businessCity,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // handleAlert('success', 'Solicitud Exitosa')
      })
      .catch((error) => {
        console.error('Error:', error);
        // handleAlert('error', 'Solicitud no Exitosa')
      });
  };

  emailjsRouting = () => {
    const { categoryValue } = this.state;
    let routing;
    switch (categoryValue) {
      case 'business-registration':
        // atencion.empresas@nerito.com
        routing = {
          serviceId: 'service_04a5om9',
          templateId: 'template_9hr8gya',
          userId: 'user_UYQFJzjxhj68FkO6otn5T',
        };
        return routing;
      case 'tech-support':
        // atencion.usuarios@nerito.com
        routing = {
          serviceId: 'service_vhvlxes',
          templateId: 'template_sf9d9mu',
          userId: 'user_dBLh6Yr4lUSGpuf9E46gs',
        };
        return routing;
      case 'work-with-us':
        // atraccion.talento@nerito.com
        routing = {
          serviceId: 'service_nqibcs7',
          templateId: 'template_rckjxtm',
          userId: 'user_zyxl9L3UvAKLuMIPn2OdM',
        };
        return routing;
      case 'data-privacy':
        // privacidad.datos@nerito.com
        routing = {
          serviceId: 'service_z1qe9yk',
          templateId: 'template_kwzesxr',
          userId: 'user_rKTwtzGc7MEadCJ72n7qR',
        };
        return routing;
      case 'other':
        // atencion.usuarios@nerito.com
        routing = {
          serviceId: 'service_vhvlxes',
          templateId: 'template_sf9d9mu',
          userId: 'user_dBLh6Yr4lUSGpuf9E46gs',
        };
        return routing;
      default:
        // atencion.usuarios@nerito.com
        routing = {
          serviceId: 'service_vhvlxes',
          templateId: 'template_sf9d9mu',
          userId: 'user_dBLh6Yr4lUSGpuf9E46gs',
        };
        return routing;
    }
  };

  sendEmail = (e) => {
    const {
      businessMode,
      categoryValue,
      businessName,
      contactName,
      businessPhoneNumber,
      contactEmail,
      businessState,
      businessCity,
      message,
      privacyCheckbox,
      successRecaptcha,
    } = this.state;
    let hasErrors = false;
    e.preventDefault();
    if (businessMode) {
      // Validation statement for BUSINESS mode
      if (categoryValue === '') {
        this.setState({
          categoryValueAlert: 'Debes seleccionar una categoría',
        });
        hasErrors = true;
      } else {
        this.setState({ categoryValueAlert: '' });
      }
      if (!CheckValue('empty', businessName)) {
        this.setState({
          businessNameAlert: 'Debes escribir el nombre de la empresa',
        });
        hasErrors = true;
      } else {
        this.setState({ businessNameAlert: '' });
      }
      if (!CheckValue('empty', contactName)) {
        this.setState({ contactNameAlert: 'Debes escribir tu nombre' });
        hasErrors = true;
      } else {
        this.setState({ contactNameAlert: '' });
      }
      if (!CheckValue('empty', businessPhoneNumber)) {
        this.setState({
          businessPhoneNumberAlert: 'Debes escribir un número telefónico',
        });
        hasErrors = true;
      } else if (businessPhoneNumber.length !== 10) {
        this.setState({
          businessPhoneNumberAlert:
            'El número telefónico debe de tener 10 dígitos',
        });
        hasErrors = true;
      } else {
        this.setState({ businessPhoneNumberAlert: '' });
      }
      if (!CheckValue('empty', contactEmail)) {
        this.setState({
          contactEmailAlert: 'Debes escribir un correo electrónico',
        });
        hasErrors = true;
      } else if (!CheckValue('email', contactEmail)) {
        this.setState({
          contactEmailAlert:
            'El correo electrónico debe tener este formato: ejemplo@mail.com',
        });
        hasErrors = true;
      } else {
        this.setState({ contactEmailAlert: '' });
      }
      if (!CheckValue('empty', businessState)) {
        this.setState({ businessStateAlert: 'Debes seleccionar un Estado' });
        hasErrors = true;
      } else {
        this.setState({ businessStateAlert: '' });
      }
      if (!CheckValue('empty', businessCity)) {
        this.setState({ businessCityAlert: 'Debes seleccionar una Ciudad' });
        hasErrors = true;
      } else {
        this.setState({ businessCityAlert: '' });
      }
      if (!CheckValue('empty', message)) {
        this.setState({ messageAlert: 'Debes escribir un mensaje' });
        hasErrors = true;
      } else {
        this.setState({ messageAlert: '' });
      }
      if (!privacyCheckbox) {
        this.setState({
          privacyCheckboxAlert: 'Debes aceptar la Política de Privacidad',
        });
        hasErrors = true;
      } else {
        this.setState({ privacyCheckboxAlert: '' });
      }
      if (!successRecaptcha) {
        this.setState({
          successRecaptchaAlert: 'Debes pasar el reCAPTCHA',
        });
        hasErrors = true;
      } else {
        this.setState({ successRecaptchaAlert: '' });
      }
      console.log('test' + hasErrors);
      if (hasErrors) {
        console.log('Error en BUSINESS');
      } else {
        const routing = this.emailjsRouting();
        emailjs
          .sendForm(
            routing.serviceId,
            routing.templateId,
            e.target,
            routing.userId
          )

          .then(
            (result) => {
              console.log(result.text);
            },
            (error) => {
              console.log(error.text);
            }
          );
        // e.target.reset();
        this.sendLead(e);
        //reset fields
        this.resetFields();
        // success modal
        this.setState({ successModalVisible: true });
        console.log('El envío de BUSINESS mode está conectado');
      }
    } else {
      // Validation statement for CONTACT mode
      if (categoryValue === '') {
        this.setState({
          categoryValueAlert: 'Debes seleccionar una categoría',
        });
        hasErrors = true;
      } else {
        this.setState({ categoryValueAlert: '' });
      }
      if (!CheckValue('empty', contactName)) {
        this.setState({ contactNameAlert: 'Debes escribir tu nombre' });
        hasErrors = true;
      } else {
        this.setState({ contactNameAlert: '' });
      }
      if (!CheckValue('empty', contactEmail)) {
        this.setState({
          contactEmailAlert: 'Debes escribir un correo electrónico',
        });
        hasErrors = true;
      } else if (!CheckValue('email', contactEmail)) {
        this.setState({
          contactEmailAlert:
            'El correo electrónico debe tener este formato: ejemplo@mail.com',
        });
        hasErrors = true;
      } else {
        this.setState({ contactEmailAlert: '' });
      }
      if (!CheckValue('empty', message)) {
        this.setState({ messageAlert: 'Debes escribir un mensaje' });
        hasErrors = true;
      } else {
        this.setState({ messageAlert: '' });
      }
      if (!privacyCheckbox) {
        this.setState({
          privacyCheckboxAlert: 'Debes aceptar la Política de Privacidad',
        });
        hasErrors = true;
      } else {
        this.setState({ privacyCheckboxAlert: '' });
      }
      if (!successRecaptcha) {
        this.setState({
          successRecaptchaAlert: 'Debes pasar el reCAPTCHA',
        });
        hasErrors = true;
      } else {
        this.setState({ successRecaptchaAlert: '' });
      }
      console.log('test' + hasErrors);
      if (hasErrors) {
        console.log('Error en CONTACT');
      } else {
        const routing = this.emailjsRouting();
        emailjs
          .sendForm(
            routing.serviceId,
            routing.templateId,
            e.target,
            routing.userId
          )

          .then(
            (result) => {
              console.log(result.text);
            },
            (error) => {
              console.log(error.text);
            }
          );
        e.target.reset();
        // this.sendLead(e);
        //reset fields
        this.resetFields();
        // success modal
        this.setState({ successModalVisible: true });
        console.log('El envío de CONTACT mode está conectado');
      }
    }
  };

  handleRecaptcha = () => {
    this.setState({ successRecaptcha: true, successRecaptchaAlert: '' });
  };

  // this function changes the form from BUSINESS to CONTACT
  handleSelectChange = (e) => {
    this.handleInputChange(e);
    if (e.target.value === 'business-registration') {
      this.setState({ businessMode: true });
      console.log('BUSINESS MODE ' + e.target.value);
    } else {
      this.setState({ businessMode: false });
      console.log('CONTACT MODE ' + e.target.value);
      this.setState({
        businessName: '',
        businessPhoneNumber: '',
        businessState: '',
        businessCity: '',
      });
    }
  };

  handleCheckboxChange = (e) => {
    this.handleInputChange(e);
    this.setState({ formIsComplete: true });
  };

  // this function changes the state of the input the user is editing
  handleInputChange = (e) => {
    const keyValue = {};
    // ternary to check if field is normal input or checkbox, because the key-value to update is different
    const value =
      e.target.name === 'privacyCheckbox' ? e.target.checked : e.target.value;
    keyValue[e.target.name] = value;
    this.setState(keyValue);
  };

  resetFields = () => {
    this.setState({
      // form fields
      businessName: '',
      contactName: '',
      businessPhoneNumber: '',
      contactEmail: '',
      businessState: '',
      businessCity: '',
      message: '',
      privacyCheckbox: false,
    });
  };

  isFormValid = () => {
    const {
      businessMode,
      businessName,
      contactName,
      businessPhoneNumber,
      contactEmail,
      businessState,
      businessCity,
      message,
    } = this.state;
    return businessMode
      ? businessName &&
          contactName &&
          businessPhoneNumber &&
          contactEmail &&
          businessPhoneNumber &&
          contactEmail &&
          businessState &&
          businessCity &&
          message
      : contactName && contactEmail && message;
  };
}
