export const QuestionItems = [
  {
    question: "¿Por qué debo de completar mi perfil?",
    answer1:
      "De esa forma te aseguras que en un futuro puedes recuperar tu cuenta en caso de olvidar tu contraseña.",
  },
  {
    question: "¿Cómo puedo editar mi perfil?",
    answer1:
      "Abre el menú desplegable, y presiona 'Mi perfil'. Ahí podrás actualizar tu fecha de nacimiento, email, contraseña y tu foto de perfil",
  },
  // {
  //   question: "¿Qué son las operaciones con QR?",
  //   answer1:
  //     "Consisten en códigos generados automáticos que duran un par de minutos, los puedes usar en vez de utilizar tu tarjeta <br/> Para hacer operaciones por QR: <br/> En la pestaña de inicio, presiona el botón 'Paga, Renta o Deposita'.",
  // },
  // {
  //   question: "¿Cómo enviar dinero?",
  //   answer1: "En la pestaña de inicio, presiona el botón 'Enviar Dinero'.",
  // },
  // {
  //   question: "¿Cómo solicitar un préstamo?",
  //   answer1: "En la pestaña de inicio, presiona el botón 'Solicitar Préstamo'.",
  // },
  // {
  //   question: "¿Cómo dividir una cuenta?",
  //   answer1: "En la pestaña de inicio, presiona el botón 'Nueva Coperacha'.",
  // },
  // {
  //   question: "¿Mis amigos pueden ver mis préstamos y envíos?",
  //   answer1: "Sólo si así lo quieres, al finalizar exitosamente cualquier movimiento una nueva ventana aparece, por default sólo tus Mejores Amigos pueden ver tus movimientos, pero si gustas, puedes hacer el movimiento público, o elegir no compartirlo a nadie.",
  // },
  {
    question: "¿Puedo editar el status de mis amigos?",
    answer1:
      "Sí, abre el menú desplegable, y presiona 'Mis amigos'. Para cambiar entre Amigos/Mejores Amigos presiona el ícono a la derecha del usuario y elige el status que prefieras.",
  },
  // {
  //   question: "¿Cómo recargar saldo a mi cuenta Nerito?",
  //   answer1: "En la pestaña de inicio, presiona el botón 'Recargar Saldo'.",
  // },
  {
    question: "¿Cómo recupero mi contraseña?",
    answer1: "En login, presiona el botón 'Recuperar contraseña'.",
  },
  // {
  //   question: "¿Qué es una coperacha?",
  //   answer1: "Es dividir el pago, puedes usar esta función al salir con tus amigos, un usuario paga la cuenta al restaurante y los demás le pagan dividiendo exactamente lo que cada quien consumió.",
  // },
  {
    question: "¿Mi buzón de notificaciones es privado?",
    answer1: "Sí, tu buzón solo puede ser visto por tu cuenta. Este buzón te sirve para saber cuando un amigo acepto tu solicitud de préstamo o cuando él te la envía.",
  },
];
