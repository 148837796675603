import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import NeritoHome from '../../assets/images/NeritoHome.png';
import PrimaryButton from '../../components/common/primaryButton';

import theme from '../../styles/theme.js';
import '../../App.css';

const OurApp = () => {
  const classes = useStyles();

  return (
    <div className={classes.ourAppContainer} >
      <div className={classes.halfContainer}>
        <div>
          <Typography className={classes.blackTitle}> Explora lo que </Typography>
          <Typography className={classes.greenTitle}>Nerito ofrece</Typography>
          <Typography className={classes.thinTitle}>COMO CUENTAS INDIVIDUALES</Typography>
        </div>
        <img src={NeritoHome} alt='' className={classes.image} />
        <Link to='/nuestraApp' target='_top'>
          <PrimaryButton marginVertical={'20px'} buttonText={classes.buttonText} minWidth={'300px'}>Descargar Nerito</PrimaryButton>
        </Link>
      </div>

      <div className={classes.halfLeftContainer}>
        <div className={classes.textContainer}>
          <FontAwesomeIcon icon={faUserGroup} size='5x' color={theme.palette.yellow.main} />

          <div className={classes.titleContainer}>
            <Typography className = {classes.infoTitle}>
              Con una cuenta en nerito, tu podras:
            </Typography>
            <Typography className = {classes.info}>
              Sustituir o complementar el uso de una cuenta bancaria​​
            </Typography>
          </div>

          <ul>
            <li className = {classes.list}>
              Depositar y retirar efectivo, 
              recibir transferencias desde cuentas 
              bancarias o enviar hacia ellas,
              o desde otras cuentas de nerito.​
            ​</li>
            <li className = {classes.list}>
              No necesitas un cajero o ir al banco,
              cualquier establecimeinto que use Nerito 
              podra servirte para depositar o retirar efectivo​
            ​</li>
            <li className = {classes.list}>
              Las transferencias entre cuentas de nerito no te cuesta!​
            </li>
            <li className = {classes.list}>
              Pagar solamente usando tu celular,
              sin necesidad de una tarjeta en cualquier
              establecimeinto que use Nerito​
            </li>
          </ul>
        </div>
      </div>

    </div>
  )
}

export default OurApp;

const useStyles = makeStyles({
  ourAppContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    padding: 50,
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
      padding: 30,
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  greenTitle: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: 60,
    color: theme.palette.primary.main,
    padding: 0,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      fontSize: 40
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 35,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 28,
    },
  },
  thinTitle: {
    fontFamily: 'Lato',
    fontWeight: '300',
    fontSize: 24,
    [theme.breakpoints.down('md')]: {
      fontSize: 20
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
      textAlign: 'center',
    },
  },
  blackTitle: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: 60,
    color: theme.palette.black.main,
    padding: 0,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      fontSize: 40
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 35,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 28,
    },
  },
  infoTitle: {
    fontFamily: 'Lato',
    fontWeight: '900',
    fontSize: '32px',
    [theme.breakpoints.down('md')]: {
      fontSize: '24px',
    },
  },
  info: {
    fontFamily: 'Montserrat',
    fontWeight: '500',
    fontSize: 20,
    [theme.breakpoints.down('md')]: {
      fontSize: '18px',
    },
  },
  infoBold: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: '20px',
    [theme.breakpoints.down('md')]: {
      fontSize: '18px',
    },
  },
  halfContainer: {
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      margin: 0,
      alignItems: 'center',
      width: '100%', 
    },
  },
  halfLeftContainer: {
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginLeft: 100,
    [theme.breakpoints.down('md')]: {
      margin: 0,
      alignItems: 'center',
      width: '100%', 
    },
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 30,
    width: '80%',
    height: '100%',
    padding: 50,
    textAlign: 'justify',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
      gap: 20,
      padding: 30,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: 10,
      gap: 10,
    },
  },
  buttonText: {
    fontFamily: 'Montserrat',
    fontWeight: '600',
    fontSize: '24px',
  },
  image: {
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
      // padding: 30,
      width: '100%'
      // flexDirection: 'column'
    },
    [theme.breakpoints.down('xs')]: {
      padding: 10,
    },
  },
  list: {
    lineHeight: 2,
    fontSize: 20,
    textAlign: 'justify',
    '&::marker': {
      color: theme.palette.yellow.main
    }
  }
});