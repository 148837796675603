import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../../styles/theme.js';

const useStyles = makeStyles({
  container: {
    backgroundColor: (props) => props.color || theme.palette.yellow.main,
    color: theme.palette.black.main,
    border: `3px solid ${theme.palette.yellow.main}`,
    borderRadius: 100,
    paddingLeft: (props) => props.paddingHorizontal || 10,
    paddingRight: (props) => props.paddingHorizontal || 10,
    paddingTop: 5,
    paddingBottom: 5,
    marginLeft: (props) => props.marginHorizontal || 5,
    marginRight: (props) => props.marginHorizontal || 5,
    marginTop: (props) => props.marginVertical || 5,
    marginBottom: (props) => props.marginVertical || 5,
    width: (props) => (props.fullWidth ? '100%' : 'auto'),
    minWidth: (props) => props.minWidth || null,
    alignItems: (props) => props.alignItems || 'center', // align vertically
    boxShadow: theme.shadows[5],
    ...theme.typography.button,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    outline: 'none',
    cursor: 'pointer'
  },
});

const SecondaryButton = (props) => {
  const classes = useStyles(props);

  return (
    <>
      <button
        type={props.type || 'button'}
        onClick={props.onClick}
        className={`${props.primaryButtonStyle || null} ${classes.container}`}
        disabled={props.disabled}
      >
        <text className={props.buttonText || null}>{props.children}</text>
      </button>
    </>
  );
};

export default SecondaryButton;
