import React, {useState, useRef} from "react";
import "./Accordion.css";
import Chevron from "./Chevron";
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../styles/theme.js'

const useStyles = makeStyles({

  accordionSection: {
    display: 'flex',
    flexDirection: 'column',
  },
  accordion: {
    backgroundColor: '#eee',
    color: '#444',
    cursor: 'pointer',
    padding: '18px',
    display: 'flex',
    alignItems: 'center',
    border: 'none',
    outline: 'none',
    transition: 'backgroundColor 0.6s ease',
  },
  accordionTitle: {
    fontWeight: '600',
    fontSize: '1rem',
    textAlign: 'left',
    color: '#434343',
    color:'#317E6B'
  },
  spacer: {
    width: '10px',
    height: '10px',
    marginBottom: '30px',
  },
  accordionContent: {
    backgroundColor: '#fff',
    overflow: 'hidden',
    transition: 'max-height 0.6s ease',
    color: '#444',
  },
  accordionText: {
    fontWeight: '400',
    fontSize: '1rem',
    padding: '18px',
  }

})

function Accordion(props) {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [setRotate, setRotateState] = useState("accordion__icon");

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    );
    setRotateState(
      setActive === "active" ? "accordion__icon" : "accordion__icon rotate"
    );
  }

  const content = useRef(null);

  const classes = useStyles();

  return (
    <div className={classes.accordionSection}>
      <button className={`${classes.accordion} ${setActive}`} onClick={toggleAccordion}>
        <p className={classes.accordionTitle}>{props.title}</p>
        <div className={classes.spacer}></div>
        <Chevron className={`${setRotate}`} width={10} fill={"#317E6B"}></Chevron>
      </button>
      <div
        ref={content}
        style={{maxHeight: `${setHeight}`}}
        className={classes.accordionContent}
      >
        <div
          className={classes.accordionText}
          dangerouslySetInnerHTML={{__html: props.content}}
        ></div>
      </div>
    </div>
  );
}

export default Accordion;
