import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../../styles/theme.js';

const useStyles = makeStyles({
  verticalContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: (props) => props.marginLeft || 5,
    marginRight: (props) => props.marginRight || 5,
    marginTop: (props) => props.marginVertical || 5,
    marginBottom: (props) => props.marginVertical || 5,
    width: (props) => (props.fullWidth ? '100%' : 'auto'),
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.background.main,
    color: theme.palette.gray.main,
    border: 'none',
    borderRadius: 10,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    width: (props) => (props.fullWidth ? '100%' : 'auto'),
    maxWidth: '100%',
    ...theme.typography.input,
  },
  error: {
    ...theme.typography.errorText,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 5,
    whiteSpace: 'nowrap'
  }
});

const PrimarySelect = (props) => {
  const classes = useStyles(props);

  return (
    <>
      <div 
      className={classes.verticalContainer}
      >
        <select
          value={props.value || undefined}
          onChange={props.onChange || null}
          name={props.name || undefined}
          className={classes.container}
          inputProps={props.inputProps || null}
        >
          {props.content}
        </select>
        {props.error && <text className={classes.error}>{props.error}</text>}
      </div>
    </>
  );
};

export default PrimarySelect;
