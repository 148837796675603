import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../styles/theme.js';
import NeritoLogoGreen from '../../assets/images/logos/neritoLogoGreen.svg';
import { Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faFacebookF, 
  faInstagram,
  faXTwitter,
  faTiktok,
  faLinkedinIn,
  faYoutube 
} from '@fortawesome/free-brands-svg-icons';

function Footer() {
  const classes = useStyles();

  return (
    <div className={classes.footerContainer}>
      <div className={classes.logoContainer}>
        <Link to="/">
          <img
            src={NeritoLogoGreen}
            alt=""
            className={classes.imgContainer}
          />
        </Link>
      </div>
      <div className={classes.footerFilter}>
        <div className={classes.footerTop}>
          {/* <text>© MONECTA, I.F.P.E. S.A. de C.V. </text> */}
          <text className={classes.copyright}>Copyright © 2022 Nerito - Todos los derechos reservados.</text>
        </div>
        <div className={classes.termsContainer}>
          <Link to='/terminos' target='_top'>
            <Typography className={classes.link}>Términos y condiciones</Typography>
          </Link>
          <Link to='/politica-de-privacidad' target='_top'>
            <Typography className={classes.link}>Aviso de privacidad</Typography>
          </Link>
        </div>
        <div className={classes.singleRowLogo}>
          <a
            href="https://www.facebook.com/NeritoApp/?modal=suggested_action&notif_id=1614122387213523&notif_t=page_user_activity&ref=notif"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon className={classes.socialLogo} icon={faFacebookF} size="lg"  color={theme.palette.primary.main} />
          </a>
          <a
            href="https://www.instagram.com/neritooficial_latam/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon className={classes.socialLogo} icon={faInstagram} size="lg" color={theme.palette.primary.main} />
          </a>
          <a
            href="https://twitter.com/neritoapp"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon className={classes.socialLogo} icon={faXTwitter} size="lg" color={theme.palette.primary.main} />
          </a>
          <a
            href="https://www.tiktok.com/@neritoapp?lang=es"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon className={classes.socialLogo} icon={faTiktok} size="lg" color={theme.palette.primary.main} />
          </a>
          <a
            href="https://www.linkedin.com/company/75551661/admin/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon className={classes.socialLogo} icon={faLinkedinIn} size="lg" color={theme.palette.primary.main} />
          </a>
          <a
            href="https://www.youtube.com/channel/UCr1YHcmay8HXGzQ6ymNwwTw"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon className={classes.socialLogo} icon={faYoutube} size="lg" color={theme.palette.primary.main} />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;

const useStyles = makeStyles({
  footerContainer: {
    width: '100%',
    backgroundColor: theme.palette.white.main,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    zIndex: '-1',
    display: 'flex',
    flexDirection: 'row',
    height: 200,
    alignItems: 'center',
    marginBottom: 0,
    boxShadow: theme.shadows[5],
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      height: 'auto',
      paddingTop: '4%',
      paddingBottom: '4%'
    }
  },
  imgContainer: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '50',
    }
  },
  footerFilter: {
    width: '60%',
    zIindex: '1',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '75%',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    }
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40%',
    borderRightWidth: '3px',
    borderRightColor: theme.palette.secondary.main,
    borderRightStyle: 'solid',
    height: '75%',
    [theme.breakpoints.down('sm')]: {
      borderRightWidth: 0,
      borderRightColor: 'none',
      borderRightStyle: 'none',
      borderBottomWidth: '3px',
      borderBottomColor: theme.palette.secondary.main,
      borderBottomStyle: 'solid',
      marginBottom: 15
    }
  },
  footerTop: {
    textAlign: 'center',
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0
    }
  },
  copyright: {
    fontSize: 14,
    paddingTop: 10,
    color: theme.palette.black.main,
    fontFamily: 'Lato',
    fontWeight: '500',
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    }
  },
  singleRowLogo: {
    display: '-ms-flexbox',
    display: 'flex',
    MsFlexWrap: 'wrap',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 0
    }
  },
  socialLogo: {
    width: 50,
    margin: '5px 10px',
    borderRadius: 25,
    [theme.breakpoints.down('sm')]: {
      width: 30,
    }
  },
  footerBottom: {
    zIndex: '2',
    position: 'relative',
    paddingBottom: '40px',
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
  navLi: {
    display: 'inline-block',
    padding: '0px 0 0 8px',
    transition: 'all 0.3s ease 0s',
  },
  link: {
    fontSize: 14,
    color: theme.palette.primary.main,
    fontFamily: 'Lato',
    fontWeight: '600',
    textDecorationColor: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    }
  },
  termsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '50%',
    alignSelf: 'center',
    marginBottom: '10px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      gap: 10
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    }
  }
})
