// EDIT 23•FEB•21 Teams' version
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
const awsmobile = {
  aws_project_region: 'us-west-2',
  aws_cognito_identity_pool_id: 'us-west-2:3479bd2b-2ca6-435c-9ea5-6141bd2553fb',
  aws_cognito_region: 'us-west-2',
  aws_user_pools_id: 'us-west-2_4p0LDBjGl',
  aws_user_pools_web_client_id: '6el136k9he47f7s5ji9a0ad6pb',
  oauth: {
    domain:
    'flexwalletapi2-monectaauthbyemail-hosteduidomain-prod.auth.us-west-2.amazoncognito.com',
    scope: [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn: 'myapp://',
    redirectSignOut: 'myapp://',
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_POOLS',
  aws_cloud_logic_custom: [
    {
      name: 'profile-api',
      endpoint: 'https://gie3c8kaug.execute-api.us-west-2.amazonaws.com/prod',
      region: 'us-west-2',
    },
    {
      name: 'transaction-api',
      endpoint: 'https://7ajotawdkj.execute-api.us-west-2.amazonaws.com/prod',
      region: 'us-west-2',
    },
    {
      name: 'credit-api',
      endpoint: 'https://qwmgxe2x0b.execute-api.us-west-2.amazonaws.com/prod',
      region: 'us-west-2',
    },
    {
      name: 'event-api',
      endpoint: 'https://toau3n4qcj.execute-api.us-west-2.amazonaws.com/prod',
      region: 'us-west-2',
    },
    {
      name: 'inventory-api',
      endpoint: 'https://xm7gxb68kj.execute-api.us-west-2.amazonaws.com/prod',
      region: 'us-west-2',
    },
    {
      name: 'invitation-api',
      endpoint: 'https://8w9wc55khk.execute-api.us-west-2.amazonaws.com/prod',
      region: 'us-west-2',
    },
    {
      name: 'invoice-api',
      endpoint: 'https://8m2nfkgdwd.execute-api.us-west-2.amazonaws.com/prod',
      region: 'us-west-2',
    },
    {
      name: 'payment-api',
      endpoint: 'https://jyxkse3qkf.execute-api.us-west-2.amazonaws.com/prod',
      region: 'us-west-2',
    },
    {
      name: 'product-api',
      endpoint: 'https://ogxx35pz3l.execute-api.us-west-2.amazonaws.com/prod',
      region: 'us-west-2',
    },
    {
      name: 'setting-api',
      endpoint: 'https://5vrrrf1e9d.execute-api.us-west-2.amazonaws.com/prod',
      region: 'us-west-2',
    },
    {
      name: 'store-api',
      endpoint: 'https://lxmf4e7zn5.execute-api.us-west-2.amazonaws.com/prod',
      region: 'us-west-2',
    },
    {
      name: 'merchant-api',
      endpoint: 'https://udk0d7auoe.execute-api.us-west-2.amazonaws.com/prod',
      region: 'us-west-2',
    },
    {
      name: 'inshop-api',
      endpoint: 'https://y52q5g4nzd.execute-api.us-west-2.amazonaws.com/prod',
      region: 'us-west-2',
    },
    {
      name: 'keys-api',
      endpoint: 'https://d2iad48d4h.execute-api.us-west-2.amazonaws.com/prod',
      region: 'us-west-2',
    },
    {
      name: 'funds-api',
      endpoint: 'https://0sqczfiwch.execute-api.us-west-2.amazonaws.com/prod',
      region: 'us-west-2',
    },
    {
      name: 'notifications-push-api',
      endpoint: 'https://6yy3p89d20.execute-api.us-west-2.amazonaws.com/prod',
      region: 'us-west-2',
    },
    {
      name: 'speis-api',
      endpoint: 'https://7tdswa1h06.execute-api.us-west-2.amazonaws.com/prod',
      region: 'us-west-2',
    }
  ],
  aws_appsync_graphqlEndpoint: 'https://nlmuug2vxbgqtf64naryt57ddi.appsync-api.us-west-2.amazonaws.com/graphql',
  aws_webSocket_Endpoint: 'wss://ckywtzuo14.execute-api.us-west-2.amazonaws.com/prod',
  aws_appsync_region: 'us-west-2',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_user_files_s3_bucket: 'flexwalletapi2-bucket-prod-us-west-2',
  aws_user_files_s3_bucket_region: 'us-west-2',
};

export default awsmobile;
