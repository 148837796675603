import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight,faCircle } from '@fortawesome/free-solid-svg-icons';
import theme from '../../../styles/theme.js';

const PrimaryCarousel = (props) => {
  const classes = useStyles();
  const [currentStep, setCurrentStep] = useState(0)

  const handleStep = (typeStep) => {
    const step = typeStep ? currentStep + 1 : currentStep - 1
    if(step < 0 ) {
      setCurrentStep(0)
    }
    else if(step > props.data.length){
      setCurrentStep(props.data.length - 1)
    }
    else {
      setCurrentStep(step)
    }
  }

  const renderIndicator = (onClickHandler, isSelected, index, label) => {
    return (
      <button
        className={classes.indicationContainer}
        onClick={onClickHandler}
        onKeyDown={onClickHandler}
        value={index} 
        key={index}
        tabIndex={0}
        aria-label={`${label} ${index + 1}`}    
      >
        <FontAwesomeIcon 
          icon={faCircle} 
          size='1x' 
          color={isSelected ? theme.palette.yellow.main : theme.palette.lightGrey.main} 
        />
      </button>
    )
  }

  return (
    <main className={classes.container}>
      <button
        className={classes.steps}
        onClick={() => handleStep()}
      >
        <FontAwesomeIcon icon={faChevronLeft} size='1x' color={theme.palette.black.main} /> 
      </button>
      <Carousel
        showStatus = {false}
        showThumbs = {false}
        showArrows = {false}
        emulateTouch = {true}
        width = '80%'
        className={classes.carouselContainer}
        selectedItem = {currentStep}
        renderIndicator={
          (onClickHandler, isSelected, index, label) => 
          renderIndicator(onClickHandler, isSelected, index, label)
        }
      >
        {
          props.data.map(it => (
            <div>
              <img src={it} />
            </div>
          ))
        }
      </Carousel>
      <button
        className={classes.steps}
        onClick={() => handleStep(true)}
      >
        <FontAwesomeIcon icon={faChevronRight} size='1x' color={theme.palette.black.main} /> 
      </button>
    </main>
  )
}

export default PrimaryCarousel

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '3%'
  },
  carouselContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  indicationContainer: {
    border: 'none',
    background: 'transparent',
    margin: '0 10px',
    cursor: "pointer"
  },
  steps: {
    borderRadius: '50%',
    boxShadow: '0 14px 10px 0 rgba(0,0,0,.16)',
    padding: 15,
    border: 'none',
    background: 'transparent',
    display: 'flex',
  }
});