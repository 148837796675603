import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../../styles/theme.js';

const useStyles = makeStyles({
  verticalContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: (props) => props.marginLeft || 5,
    marginRight: (props) => props.marginRight || 5,
    marginTop: (props) => props.marginVertical || 5,
    marginBottom: (props) => props.marginVertical || 5,
    width: (props) => (props.fullWidth ? '100%' : 'auto'),
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.background.main,
    color: theme.palette.gray.main,
    border: 'none',
    borderRadius: 10,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: (props) => (props.paddingTop ? props.paddingTop : 5),
    paddingBottom: (props) => (props.paddingBottom ? props.paddingBottom : 5),
    // marginLeft: 5,
    // marginRight: (props) => props.marginRight || 5,
    // marginTop: (props) => props.marginVertical || 5,
    // marginBottom: (props) => props.marginVertical || 5,
    // width: (props) => (props.fullWidth ? '100%' : 'auto'),
    ...theme.typography.input,
  },
  startAndInputContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: (props) =>
      props.centerInputText ? 'center' : 'flex-start',
    width: (props) => (props.centerInputText ? '100%' : 'auto'),
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: (props) =>
      props.centerInputText ? 'center' : 'flex-start',
    width: (props) => (props.centerInputText ? '100%' : 'auto'),
  },
  input: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: (props) =>
      props.centerInputText ? 'center' : 'flex-start',
    textAlign: (props) => (props.centerInputText ? 'center' : 'auto'),
    alignContent: 'center',
    minWidth: 0,
    backgroundColor: (props) => (props.color ? props.color : theme.palette.background.main),
    paddingLeft: (props) => (props.paddingLeft || 5),
    paddingRight: (props) => (props.paddingRight || 5),
    color: theme.palette.gray.main,
    ...theme.typography.input,
    border: 'none',
    borderRadius: 10,
  },
  startAdornment: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: 5,
  },
  endAdornment: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  error: {
    ...theme.typography.errorText,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 5,
    whiteSpace: 'nowrap'
  }
});

const PrimaryInput = (props) => {
  const classes = useStyles(props);

  return (
    <>
      <div className={classes.verticalContainer}>
        <text className={classes.error}>{props.label}</text>
        <div className={classes.container}>
          <div className={classes.startAndInputContainer}>
            {props.startAdornment && (
              <div className={classes.startAdornment}>
                {props.startAdornment}
              </div>
            )}
            <div className={classes.inputContainer}>
              <input
                id={props.id || undefined}
                name={props.name || undefined}
                type={props.type || 'text'}
                placeholder={props.placeholder || null}
                value={props.value || undefined}
                onChange={props.onChange || null}
                className={classes.input}
                autoFocus={props.autoFocus || false}
                onKeyPress={props.keyPress || null}
              />
            </div>
          </div>
          {props.endAdornment && (
            <div className={classes.endAdornment}>{props.endAdornment}</div>
          )}
        </div>
        {props.error && <text className={classes.error}>{props.error}</text>}
      </div>
    </>
  );
};

export default PrimaryInput;
