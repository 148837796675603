import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages/Home';
import Ayuda from './pages/help';
import AyudaApp from './pages/help/Ayuda_App.js';
import Contacto from './pages/Contacto';
// import ContactoMerchant from './components/pages/ContactoMerchant';
import Servicios from './pages/Servicios';
import Seguridad from './pages/Seguridad';
import Comercios from './pages/Comercios';
import Terminos from './pages/termsAndConditions';
import Privacidad from './pages/privacyNotice';
import Registro from './pages/register';
import Verify from './pages/verify';
import { SnackbarProvider } from 'notistack';
import './App.css';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import OurApp from './pages/OurApp';

const App = () => {
  return (
    <>
      <SnackbarProvider maxSnack={3}>
        <Router>
          <NavBar />
          <Switch>
            <Route path='/' exact component={Home}></Route>
            <Route path='/home' exact component={Home}></Route>
            <Route path='/ayuda' exact component={Ayuda}></Route>
            <Route path='/ayuda_app' exact component={AyudaApp}></Route>
            <Route path='/contacto' exact component={Contacto}></Route>
            {/* <Route
              path='/contactoMerchant'
              exact
              component={ContactoMerchant}
            ></Route> */}
            <Route path='/servicios' exact component={Servicios}></Route>
            <Route path='/seguridad' exact component={Seguridad}></Route>
            <Route path='/comercios' exact component={Comercios}></Route>
            <Route path='/terminos' exact component={Terminos}></Route>
            <Route path='/registro' exact component={Registro}></Route>
            <Route path='/verificar' exact component={Verify} />
            <Route path='/nuestraApp' exact component={OurApp} />
            <Route
              path='/firstLogin'
              component={() => {
                window.location.href = 'https://nerito.mx/login';
                return null;
              }}
            />
            <Route
              path='/politica-de-privacidad'
              exact
              component={Privacidad}
            ></Route>
          </Switch>
          <Footer />
        </Router>
      </SnackbarProvider>
    </>
  );
};

export default App;
