import React from "react";
import "./Ayuda.css";
import {QuestionItems} from "./QuestionItems";
// import {Link} from "react-router-dom";
import Accordion from "../../components/Accordion";

export default function Ayuda() {
  return (
    <div>
      <div style={{padding: '5%',backgroundColor: 'white'}}>
        <div className="faq-board">
          {QuestionItems.map((item, index) => {
            return (
              <Accordion
                title={item.question}
                content={item.answer1}
              ></Accordion>
            );
          })}
        </div>
        <div className="bottom-area">
          {/* <h1>¿En qué te podemos ayudar?</h1>
          <p className="faq-message">
            Si tienes alguna duda sobre la app, nosotros de podemos ayudar{" "}
            <Link className="bright-yellow-text" to="/contacto_app" target="_top">
              ¡Mándanos un mensaje!
            </Link>{" "}
          </p> */}

          {/* <p className="faq-message">
          Si tienes alguna duda o necesitas ayuda al usar <br className="break"/> la app, ponte en{" "}
          <Link className="yellow-link" to="/contacto" target="_top">

            contacto
          </Link>
          {" "}
          con nosotros.
        </p> */}
        </div>
      </div>
    </div>
  );
}
