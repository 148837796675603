import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  spacing: 8,
  palette: {
    primary: {
      main: '#3ABC9C',
      secondary: '#47B097'
    },
    secondary: {
      main: '#E7D763',
    },
    white: { main: '#FFFFFF' },
    gray: { main: '#434343' },
    background: { main: '#EDEDED' },
    placeholder: { main: 'blue' },
    black: {main: '#000000' },
    yellow: { main: '#E7D763'},
    green: { main: '#2D9B80'},
    lightGrey: { main : '#D9D9D9'}
  },
  typography: {
    fontFamily: ['Nunito', 'Roboto'],
    fontSize: 16,
    fontWeightRegular: 500,
    fontWeightBold: 700,
    title: {
      fontFamily: ['Nunito', 'Roboto'],
      fontSize: 24,
      fontWeight: 700,
      textTransform: 'none',
      color: '#434343',
    },
    subtitle: {
      fontFamily: ['Nunito', 'Roboto'],
      fontSize: 20,
      fontWeight: 700,
      textTransform: 'none',
      color: '#434343',
    },
    listItem: {
      fontFamily: ['Nunito', 'Roboto'],
      fontSize: 20,
      fontWeight: 700,
      textTransform: 'none',
      color: '#434343',
    },
    bigParagraph: {
      fontFamily: ['Nunito', 'Roboto'],
      fontSize: 20,
      fontWeight: 700,
      textTransform: 'none',
      color: '#434343',
    },
    bigSlogan: {
      fontFamily: ['Nunito', 'Roboto'],
      fontSize: 20,
      fontWeight: 700,
      textTransform: 'none',
      color: '#434343',
    },
    button: {
      fontFamily: ['Nunito', 'Roboto'],
      fontSize: 16,
      fontWeight: 700,
      textTransform: 'none',
    },
    input: {
      fontFamily: ['Nunito', 'Roboto'],
      fontSize: 16,
      fontWeight: 700,
      color: '#434343',
    },
    checkbox: {
      fontFamily: ['Nunito', 'Roboto'],
      fontSize: 16,
      fontWeight: 700,
      color: '#434343',
    },
    paragraph: {
      fontFamily: ['Nunito', 'Roboto'],
      fontSize: 16,
      fontWeight: 700,
      color: '#434343',
    },
    popMenu: {
      fontFamily: ['Nunito', 'Roboto'],
      fontSize: 16,
      fontWeight: 700,
      color: '#3B9A83',
    },
    slogan: {
      fontFamily: ['Nunito', 'Roboto'],
      fontSize: 16,
      fontWeight: 800,
      color: '#434343',
    },
    link: {
      fontFamily: ['Nunito', 'Roboto'],
      fontSize: 14,
      fontWeight: 700,
      color: '#434343',
    },
    mediumText: {
      fontFamily: ['Nunito', 'Roboto'],
      fontSize: 14,
      fontWeight: 700,
      color: '#434343',
    },
    smallText: {
      fontFamily: ['Nunito', 'Roboto'],
      fontSize: 12,
      fontWeight: 700,
      color: '#434343',
    },
    errorText: {
      fontFamily: ['Nunito', 'Roboto'],
      fontSize: 10,
      fontWeight: 500,
      color: '#EE715B',
    },
    inputLabel: {
      fontFamily: ['Nunito', 'Roboto'],
      fontSize: 10,
      fontWeight: 700,
      color: '#00000061',
    },
  },
  globals: {
    cardWithNumber: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#fff',
      backgroundColor: '#ccc',
      width: '100%',
      height: 'auto',
      minWidth: 65,
      paddingTop: 5,
      paddingBottom: 5,
      borderRadius: 5,
      fontFamily: 'Nunito',
      fontWeight: 700,
      fontSize: 10,
      whiteSpace: 'nowrap',
    },
    center: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    centerVertical: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    left: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    textRight: {
      textAlign: 'right'
    }
  },
  breakpoints: {
    values: {
      mn: 1040,
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

export default theme;
