import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import ModalCookies from './modalCookies';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 4),
    outline: 'none',
  },
}));

const DefaultModal = (props) => {
  const classes = useStyles();

  const modalRouter = () => {
    switch (props.modalType) {
      case 'cookies':
        return <ModalCookies handleClose={props.handleClose} />;
      default:
        return null;
    }
  };

  return (
    <div>
      <Backdrop>
        <Modal
          aria-labelledby='transition-modal-title'
          aria-describedby='transition-modal-description'
          className={classes.modal}
          open={props.open}
          onClose={props.handleClose}
          closeAfterTransition
        >
          <Fade in={props.open}>
            <div className={classes.paper}>{modalRouter()}</div>
          </Fade>
        </Modal>
      </Backdrop>
    </div>
  );
};

export default DefaultModal;
